const columnConfig = [
  {
    key: 'description',
    label: 'Deskripsi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: '#333333',
      minWidth: '150px',
      textAlign: 'left',
      fontWeight: 400,
    },
    tdStyle: {
      fontSize: '110px',
      color: '#333333',
      minWidth: '150px',
      textAlign: 'center',
    },
    tdClass: 'text-[#828282]',
  },
  {
    key: 'status',
    label: 'Status',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: '#333333',
      minWidth: '150px',
      textAlign: 'center',
      fontWeight: 400,
    },
    tdClass: 'text-black text-center',
  },
  {
    key: 'date',
    label: 'Waktu',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: '#333333',
      minWidth: '150px',
      textAlign: 'center',
      fontWeight: 400,
    },
    tdClass: 'text-[#828282] text-center',
  },
  {
    key: 'amount',
    label: 'Nominal',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: '#333333',
      minWidth: '150px',
      textAlign: 'center',
      fontWeight: 400,
      textWrap: 'nowrap',
    },
    tdClass: 'text-black text-center',
  },
]

export default columnConfig
