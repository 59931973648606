<template>
  <BModal
    id="download-mutation"
    ref="download-mutation"
    no-stacking
    centered
    hide-footer
    hide-header-close
    size="md"
    hide-header
  >
    <div class="text-black flex flex-col">
      <div class="text-xl font-semibold mb-2 text-center">
        Download Mutasi
      </div>
      <ValidationObserver
        ref="formRules"
        v-slot="{ invalid }"
      >
        <BFormGroup class="w-full">
          <template #label>
            <div class="text-[#333333]">
              Rentang Waktu
            </div>
          </template>
          <ValidationProvider
            #default="{ errors }"
            name="Rentang Waktu"
            :rules="'required'"
          >
            <DateRangePicker
              ref="picker"
              v-model="dateRange"
              :locale-data="locale"
              :ranges="ranges"
              :opens="'center'"
              style="width: 100%"
            >
              <template v-slot:input="picker">
                <div class="flex items-center justify-between">
                  {{ DATE_NUMBER_SLASH(picker.startDate) }} -
                  {{ DATE_NUMBER_SLASH(picker.endDate) }}
                  <span
                    alt="Calendar"
                    class="h-5 ml-1 k-calendar"
                  />
                </div>
              </template>
              <small class="text-danger">{{ errors[0] }}</small>
            </DateRangePicker>
          </ValidationProvider>
        </BFormGroup>
        <BButton
          block
          :variant="invalid || isLoading ? 'secondary' : 'primary'"
          :disabled="invalid || isLoading"
          size="sm"
          class="!rounded-xl"
          @click="onSubmit"
        >
          <BSpinner
            v-if="isLoading"
            small
          />
          Download
        </BButton>
      </ValidationObserver>
    </div>
  </BModal>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  DATE_NUMBER_SLASH, LABELDAY, LABELMONTH, YEAR_MONTH_DAY,
} from '@/libs/filterDate'
import {
  firstDateOfMonth, lastDateOfLastMonth, lastMonth, today,
} from '@/store/helpers'
import { newAxiosIns } from '@/libs/axios'
import { toastError, toastSuccess } from '@/views/pages/komclass/data-event/config'
import { required } from '@validations'

export default {
  components: {
    DateRangePicker,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      DATE_NUMBER_SLASH,
      YEAR_MONTH_DAY,
      required,
      dateRange: {
        startDate: firstDateOfMonth,
        endDate: today,
      },
      ranges: {
        'Bulan Ini': [firstDateOfMonth, today],
        'Bulan Lalu': [lastMonth(1), lastDateOfLastMonth],
        '2 Bulan Terakhir': [lastMonth(2), lastDateOfLastMonth],
        '3 Bulan Terakhir': [lastMonth(3), lastDateOfLastMonth],
        'Custom Range': [today, today],
      },
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: LABELDAY,
        monthNames: LABELMONTH,
      },
      isLoading: false,
      cardId: this.$route.params.id,
    }
  },
  methods: {
    async onSubmit() {
      this.isLoading = true
      const url = '/komcards/api/v1/card/mutation/export'
      const body = {
        card_id: Number(this.cardId),
        start_date: YEAR_MONTH_DAY(this.dateRange.startDate),
        end_date: YEAR_MONTH_DAY(this.dateRange.endDate),
      }

      try {
        const { data: { data } } = await newAxiosIns.post(url, body)

        const a = document.createElement('a')
        a.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data.base64_object}`
        a.download = data.filename
        a.click()

        this.isLoading = false
        this.visibleDownlodMutation = false
        this.$bvModal.hide('download-mutation')
        toastSuccess('Data berhasil di export')
      } catch {
        this.dateRange = {
          startDate: firstDateOfMonth,
          endDate: today,
        }
        this.isLoading = false
        toastError('Gagal mengunduh mutasi')
      }
    },
  },
}
</script>
